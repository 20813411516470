.banner {
  z-index: 99;
}

.banner img {
  z-index: 99;
}

@keyframes bannerBackground {
  from {
    top: 0;
  }

  to {
    top: -87.4rem;
  }
}

.banner .background {
  animation-name: bannerBackground;
  animation-duration: 10.4s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  z-index: 99;
}

@keyframes bannerForeground {
  from {
    top: 0;
  }

  to {
    top: -84.1rem;
  }
}

.banner .foreground {
  animation-name: bannerForeground;
  animation-duration: 10s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}