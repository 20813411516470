html {
  font-size: 10px;
}

.App {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

header {
  position: absolute;
  top: 0;
  width: 100vw;
  display: flex;
  justify-content: center;
}

.App-logo {
  width: 11vh;
  margin-top: 4vh;
  pointer-events: none;
  z-index: 99;
}

input {
  width: 70%;
  max-width: 40rem;
  height: 5rem;
  margin-top: 1.8rem;
  border: 2pt solid #B7B7B7;
  border-radius: 0.4rem;
  background: black;
  color: white;
  font-size: 2rem;
  text-align: center;
  background: #2d2d2d;
}

input.invalid {
  border-color: #FA0E42;
  outline: none;
}


button {
  border-radius: 3rem;
  outline: none;
  width: 100%;
  max-width: 34rem;
  min-width: 28rem;
  height: 6.5rem;
  background: #383B3E;
  color: white;
  border: 0;
  font-family: UniversBlackExtObl;
  font-size: 1.6rem;
  letter-spacing: 0.16rem;
  cursor: pointer;
  text-transform: uppercase;
}

button:hover {
  background: #4a4e52;
}

button.active {
  background: #FF6700;
}

button.active:hover {
  background: #f78438;
}

.checkbox {
  font-family: RobotoMedium;
  color: #b7b7b7;
  display: flex;
  align-items: center;
  margin-top: 1.8rem;
  cursor: pointer;
  font-size: 1.6rem;
}

.green-tick-container {
  width: 3rem;
  height: 3rem;
  border: 0.2rem solid #B7B7B7;
  border-radius: 0.5rem;
  margin-right: 1.4rem;
  display: flex;
  justify-content: center;
  background: #2d2d2d;
}

.checkbox.invalid .green-tick-container {
  border-color: #FA0E42;
  outline: none;
}

.green-tick {
  width: 2.2rem;
}


@media only screen and (max-height: 900px) {
  html {
    font-size: 9px;
  }
}

@media only screen and (max-width: 842px) {
  html {
    font-size: 9px;
  }
}


@media only screen and (max-height: 600px) {
  html {
    font-size: 8px;
  }
}

@media only screen and (max-width: 600px) {
  html {
    font-size: 8px;
  }
}