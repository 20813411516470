/*
        This font software is the property of Commercial Type.

        You may not modify the font software, use it on another website, or install it on a computer.

        License information is available at http://commercialtype.com/eula
        For more information please visit Commercial Type at http://commercialtype.com or email us at info[at]commercialtype.com

        Copyright (C) 2021 Schwartzco Inc.
        License: 2111-XKKBYR     
*/


@font-face {
  font-family: 'Druk XXCond Web';
  src: url('DrukXXCond-SuperItalic-Web.woff2') format('woff2'),
    url('DrukXXCond-SuperItalic-Web.woff') format('woff');
  font-weight: 900;
  font-style: italic;
  font-stretch: normal;
}

.DrukXXCond-SuperItalic-Web {
  font-family: 'Druk XXCond Web';
  font-weight: 900;
  font-style: italic;
  font-stretch: normal;
}


@font-face {
  font-family: 'Druk XXCond Web';
  src: url('DrukXXCond-Super-Web.woff2') format('woff2'),
    url('DrukXXCond-Super-Web.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
}

.DrukXXCond-Super-Web {
  font-family: 'Druk XXCond Web';
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
}


@font-face {
  font-family: 'Druk XCond Web';
  src: url('DrukXCond-SuperItalic-Web.woff2') format('woff2'),
    url('DrukXCond-SuperItalic-Web.woff') format('woff');
  font-weight: 900;
  font-style: italic;
  font-stretch: normal;
}

.DrukXCond-SuperItalic-Web {
  font-family: 'Druk XCond Web';
  font-weight: 900;
  font-style: italic;
  font-stretch: normal;
}


@font-face {
  font-family: 'Druk XCond Web';
  src: url('DrukXCond-Super-Web.woff2') format('woff2'),
    url('DrukXCond-Super-Web.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
}

.DrukXCond-Super-Web {
  font-family: 'Druk XCond Web';
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
}


@font-face {
  font-family: 'Druk Cond Web';
  src: url('DrukCond-SuperItalic-Web.woff2') format('woff2'),
    url('DrukCond-SuperItalic-Web.woff') format('woff');
  font-weight: 900;
  font-style: italic;
  font-stretch: normal;
}

.DrukCond-SuperItalic-Web {
  font-family: 'Druk Cond Web';
  font-weight: 900;
  font-style: italic;
  font-stretch: normal;
}


@font-face {
  font-family: 'Druk Cond Web';
  src: url('DrukCond-Super-Web.woff2') format('woff2'),
    url('DrukCond-Super-Web.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
}

.DrukCond-Super-Web {
  font-family: 'Druk Cond Web';
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: UniversBlackExtObl;
  src: url(Univers-BlackExtObl.woff2);
}

@font-face {
  font-family: RobotoMedium;
  src: url(Roboto-Medium.woff) format('woff'),
    /* Pretty Modern Browsers */
    url(Roboto-Medium.ttf) format('truetype')
    /* Safari, Android, iOS */
}

@font-face {
  font-family: RobotoBold;
  src: url(Roboto-Bold.woff) format('woff'),
    /* Pretty Modern Browsers */
    url(Roboto-Bold.ttf) format('truetype')
    /* Safari, Android, iOS */
}