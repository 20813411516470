.container {
    width: 100vw;
    height: 100vh;
    background: black;
}

.plasticBg {
    width: 100vw;
    height: 100vh;
    position: absolute;
    z-index: 1;
    background: url('/src/assets/images/plastic-bg.png');
    background-size: cover;
}

.subContainer {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.albumCover {
    position: fixed;
    z-index: 0;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
}

.albumCover video {
    height: 100vh;
    margin-left: -20%;
}

.innerContainer {
    width: 20%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    z-index: 1;
}

.title {
    height: 25.5rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 3rem;
    font-size: 15rem;
    font-family: Druk XCond Web;
    color: white;
    text-align: center;
    letter-spacing: 0.2rem;
}

.title .success {
    white-space: nowrap;
    margin-bottom: -2rem;
}

.title img {
    height: 12rem;
}

.buttonContainer {
    width: 80vw;
    max-width: 70rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.buttonContainer button {
    margin-top: 2rem;
}

@media only screen and (max-width: 842px) {

    .albumCover video {
        margin-left: 0;
    }

    .innerContainer {
        align-items: center;
        justify-content: space-between;
        height: 60%;
        margin-top: -10rem;
    }

    .title {
        height: 19rem;
        align-items: center;
        justify-content: space-around;
        font-size: 10rem;
    }

    .title img {
        height: 8rem;
    }

    .buttonContainer {
        flex-direction: column;
    }

}